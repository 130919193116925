import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Commercial Jazz - Τμήμα Ενηλίκων";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Το μάθημα είναι σχεδιασμένο για να εξελιχθεί κάποιος στην τεχνική της
          Jazz με αυτοπεποίθηση, πάθος, ενέργεια και εκφραστικότητα.
        </P>
        <P>
          Απευθύνεται σε μαθητές με χορευτικό background. Στο πρώτο μισάωρο
          ξεκινάμε με ένα καλό ζέσταμα με στοιχεία από jazzfunk, commercial,
          lyrical και african dance.
        </P>
        <P>
          Αναπτύσσεται με δυναμικό και λεπτομερή τρόπο, με τεχνικές όπως
          στροφές, άλματα και συνδυασμούς. Στην κορύφωσή του δουλεύουμε με
          χορογραφίες από διαφορετικά στυλ, δίνοντας μεγάλη έμφαση στη δυναμική
          της κίνησης και της ερμηνείας.
        </P>
      </Section.Main>
    </Layout>
  );
}
